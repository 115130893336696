<template>
  <span>
    <v-card class="mb-0 mt-n2 parametros-v-card">
      <v-row justify="space-between" class="mx-auto mt-0">
        <v-col class="pb-0" cols="12" sm="3">
          <v-select
            class="pl-4"
            :items="regionaisList"
            v-model="regionalSelecionada"
            label="Regional selecionado"
          />
        </v-col>
        <v-col class="pb-0" cols="12" sm="3">
          <v-autocomplete
            class="pl-4"
            :items="listaMunicipios"
            v-model="municipioSelecionado"
            label="Município selecionado"
            clearable
          />
        </v-col>
        <v-col class="pb-0" cols="12" sm="3">
          <v-select
            :items="listaIndicadoresOrdenacao"
            v-model="indicadorOrdenacao"
            style="max-width: 150px !important;"
            label="Ordem Gráficos"
            :append-icon="iconTipoOrdenacao"
            @click:append="tipoOrdenacao === 'asc' ? tipoOrdenacao = 'desc' : tipoOrdenacao = 'asc'"
          />
        </v-col>
      </v-row>
    </v-card>

    <v-row dense justify="center" class="mt-3">
      <v-col
        v-for="indicador in ['qlp', 'pla', 'pmea', 'pat1', 'pat2', 'pat3', 'pat4']" 
        :key="indicador"
        cols="5" sm="3" md="auto"
        class="flex-md-grow-1"
      >
        <v-card class="my-1">
          <v-card-title>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <h5 v-on="on">{{ indicador | toUpperCase }}</h5>
              </template>
              <span>{{ traducaoIndicadores[indicador] }}</span>
            </v-tooltip>
            <status-evolucao-kpis
              class="ml-1"
              :valorAtual="dadosKpisMunicipioSelecionado[indicador] || 0 "
              :valorAnterior="dadosKpisMunicipioSelecionadoCompetenciaAnterior[indicador] || 0"
            />
          </v-card-title>
          <v-card-text>
            <div class="display-3">
              {{ 
                dadosKpisMunicipioSelecionado[indicador]
                | parseNumberToFloatBrIgnoringNaN 
              }}
              <span v-if="indicador === 'pla'">%</span>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row dense justify="center">
      <v-col
        cols="12" md="auto"
        class="flex-md-grow-1"
      >
        <v-card class="my-1">
          <v-card-text>
            <kpis-by-municipio-data-table 
            :traducaoIndicadores="traducaoIndicadores"
            :dadosKpisByMunicipio="dadosKpisByMunicipio"
            />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        cols="12" md="auto"
        class="flex-md-grow-1"
      >
        <v-card class="my-1">
          <v-card-text>
            <kpis-by-municipio-map 
              :dadosKpisByMunicipio="dadosKpisByMunicipio"
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row dense justify="center">
      <v-col cols="12">
        <v-card class="my-1">
          <v-card-title>
            <h5>QLP - Qtde. de Ligações com obra pendentes não suspensas</h5>
          </v-card-title>
          <v-card-text>
            <highcharts 
              :options=qlpChartOptions
              :deepCopyOnUpdate="false" 
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row dense justify="center">
      <v-col cols="12">
        <v-card class="my-1">
          <v-card-title>
            <h5>PLA - Percentual de ligações com obra atrasadas</h5>
          </v-card-title>
          <v-card-text>
            <highcharts 
              :options=plaChartOptions
              :deepCopyOnUpdate="false" 
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row dense justify="center">
      <v-col cols="12">
        <v-card class="my-1">
          <v-card-title>
            <h5>PMEA - Prazo Médio Lig. Pend. P/ Elab. de Est., Orç. e Projetos Atrasado</h5>
          </v-card-title>
          <v-card-text>
            <highcharts 
              :options=pmeaChartOptions
              :deepCopyOnUpdate="false" 
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row dense justify="center">
      <v-col cols="12">
        <v-card class="my-1">
          <v-card-title>
            <h5>PAT1 - Prazo Médio de Lig. Atrasada do Tipo 1</h5>
          </v-card-title>
          <v-card-text>
            <highcharts 
              :options=pat1ChartOptions
              :deepCopyOnUpdate="false" 
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row dense justify="center">
      <v-col cols="12">
        <v-card class="my-1">
          <v-card-title>
            <h5>PAT2 - Prazo Médio de Lig. Atrasada do Tipo 2</h5>
          </v-card-title>
          <v-card-text>
            <highcharts 
              :options=pat2ChartOptions
              :deepCopyOnUpdate="false" 
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row dense justify="center">
      <v-col cols="12">
        <v-card class="my-1">
          <v-card-title>
            <h5>PAT3 - Prazo Médio de Lig. Atrasada do Tipo 3</h5>
          </v-card-title>
          <v-card-text>
            <highcharts 
              :options=pat3ChartOptions
              :deepCopyOnUpdate="false" 
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row dense justify="center">
      <v-col cols="12">
        <v-card class="my-1">
          <v-card-title>
            <h5>PAT4 - Prazo Médio de Lig. Atrasada do Tipo 4</h5>
          </v-card-title>
          <v-card-text>
            <highcharts 
              :options=pat4ChartOptions
              :deepCopyOnUpdate="false" 
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </span>
</template>

<script>

import DashboardLnoService from "@/services/DashboardLnoService";

import Highcharts from 'highcharts';
import HighchartsNoData from 'highcharts/modules/no-data-to-display';
HighchartsNoData(Highcharts);
import MunicipiosService from "@/services/MunicipiosService";

import exporting from 'highcharts/modules/exporting';
import exportData from 'highcharts/modules/export-data';
exporting(Highcharts);
exportData(Highcharts);

import dayjs from 'dayjs';

export default {
  name: "DashboardLnoVisaoMunicipios",
  components: {
    KpisByMunicipioDataTable: () => import('@/components/dashboardLno/KpisByMunicipioDataTable.vue'),
    KpisByMunicipioMap: () => import('@/components/dashboardLno/KpisByMunicipioMap.vue'),
    StatusEvolucaoKpis: () => import("@/components/dashboardLno/StatusEvolucaoKpis.vue")
  },
  props: {
    competencia: {
      type: String,
      required: true,
    },
    tipoCalculo: {
      type: String,
      required: true,
    },
    activatedTab: {
      type: Number,
      required: true,
    },
    traducaoIndicadores: {
      type: Object,
      default: {}
    }
  },
  data: () => ({
    indicadores: ["qlp", "pla", "pmea", "pat1", "pat2", "pat3", "pat4", "ilp"],
    indicadorOrdenacao: "qlp",
    tipoOrdenacao: "desc",
    listaMunicipios: [],
    regionais:[],
    dadosTotais: [],
    dadosTotaisAnteriores: [],
    regionalSelecionada:null,
    municipioSelecionado: null,
    dadosKpisByMunicipio: {},
    dadosKpisByMunicipioCompetenciaAnterior: {},
    metasKpisMunicipio: [],
    qlpChartOptions: {},
    plaChartOptions: {},
    pmeaChartOptions: {},
    pat1ChartOptions: {},
    pat2ChartOptions: {},
    pat3ChartOptions: {},
    pat4ChartOptions: {},
    exportingOptions:{
      enabled: true,
        buttons: {
            contextButton: {
              align: 'right',
              verticalAlign: 'bottom',
              menuItems: [
              {
                        textKey: 'downloadPNG',
                        text: 'Baixar como PNG',
                        onclick: function () {
                            this.exportChart();
                        }
                    },
                    {
                        separator: true
                    },
                    {
                        textKey: 'downloadCSV',
                        text: 'Baixar como CSV',
                        onclick: function () {
                            this.downloadCSV();
                        }
                    },
                    {
                        textKey: 'downloadXLS',
                        text: 'Baixar como XLS',
                        onclick: function () {
                            this.downloadXLS();
                        }
                    }
              ]
            }
        }
    }
  }),
  computed: {
    regionaisList(){
      return this.regionais.map((el) => el.nome);
    },
    regionalSelecionadaId(){
      let regional = this.regionais.find((element) => element.nome == this.regionalSelecionada);
      return  regional == undefined ? 0: regional.id;
    },
    dadosKpisMunicipioSelecionado() {
      const indexes = {};

      this.indicadores.forEach(indicador => {
        if(this.municipioSelecionado == undefined){
          if (this.dadosTotais[indicador] == undefined) {
            indexes[indicador] = -1;
          } else {
            indexes[indicador] = this.dadosTotais[indicador];
          }
        }else{
          if (this.dadosKpisByMunicipio[indicador] == undefined) {
            indexes[indicador] = -1;
          } else {
            indexes[indicador] = this.dadosKpisByMunicipio[indicador].findIndex(el => el.nome_municipio === this.municipioSelecionado);
          }
        }
          
      });

      const dados = {};

      for (const indicador in indexes) {
          const index = indexes[indicador];

          index === -1 
              ? dados[indicador] = null 
              : dados[indicador] = this.municipioSelecionado == undefined ? this.dadosTotais[indicador] : this.dadosKpisByMunicipio[indicador][index].valor;
      }

      return dados;
    },
    dadosKpisMunicipioSelecionadoCompetenciaAnterior() {
      const indexes = {};

      this.indicadores.forEach(indicador => {
        if (this.municipioSelecionado == undefined) {
          if (this.dadosTotaisAnteriores[indicador] == undefined) {
            indexes[indicador] = -1;
          } else {
            indexes[indicador] = this.dadosTotaisAnteriores[indicador];
          }
        } else {
          if (this.dadosKpisByMunicipioCompetenciaAnterior[indicador] == undefined) {
            indexes[indicador] = -1;
          } else {
            indexes[indicador] = this.dadosKpisByMunicipioCompetenciaAnterior[indicador]
              .findIndex(el => el.nome_municipio === this.municipioSelecionado);
          }
        }
      });

      const dados = {};

      for (const indicador in indexes) {
        const index = indexes[indicador];

        index === -1
          ? dados[indicador] = null
          : dados[indicador] =  this.municipioSelecionado == undefined ? this.dadosTotaisAnteriores[indicador] : this.dadosKpisByMunicipioCompetenciaAnterior[indicador][index].valor;
      }

      return dados;
    },
    listaIndicadoresOrdenacao() {
      return this.indicadores
        .filter(indicador => indicador !== 'ilp')
        .map(indicador => {
          return {
            text: indicador.toUpperCase(),
            value: indicador
          }
        });
    },
    iconTipoOrdenacao() {
      if (this.tipoOrdenacao === 'asc') 
        return "mdi-sort-reverse-variant";
      else if (this.tipoOrdenacao === 'desc')
        return "mdi-sort-variant";
    },
  },
  mounted() {
    this.getDados();
    this.getRegionais(this.regionalSelecionadaId);
  },
  methods: {
    getDados() {
      // Apenas atualizado os dados se for a visao selecionada
      if (this.activatedTab !== 2) return; 

      this.$emit("updateLoadingDados", true);
      this.municipioSelecionado = undefined;
      Promise.all([
        this.getDadosKpisByMunicipio(),
        this.getDadosKpisByMunicipioCompetenciaAnterior(),
        this.getMetasKpisByMunicipio(),
        this.getDadosTotais(),
        this.getDadosTotaisAnteriores(),
      ])
        .catch(err => {
          console.error(err);
          this.$toast.error('Erro recuperar dados.', '', { position: 'topRight' });
        })
        .finally(() => this.$emit("updateLoadingDados", false));
    },
    getDadosKpisByMunicipio() {
      return DashboardLnoService.getDadosKpisByMunicipio(
        this.competencia,
        this.tipoCalculo,
        this.regionalSelecionadaId
      )
        .then(res => {
          this.dadosKpisByMunicipio = res;

          this.listaMunicipios = this.getSortedCategories();

          this.qlpChartOptions = this.getKpisByMunicipioChartOptions(
            this.dadosKpisByMunicipio.qlp,
            this.listaMunicipios,
            "qlp"
          );
          this.plaChartOptions = this.getKpisByMunicipioChartOptions(
            this.dadosKpisByMunicipio.pla,
            this.listaMunicipios,
            "pla"
          );
          this.pmeaChartOptions = this.getKpisByMunicipioChartOptions(
            this.dadosKpisByMunicipio.pmea,
            this.listaMunicipios,
            "pmea"
          );
          this.pat1ChartOptions = this.getKpisByMunicipioChartOptions(
            this.dadosKpisByMunicipio.pat1,
            this.listaMunicipios,
            "pat1"
          );
          this.pat2ChartOptions = this.getKpisByMunicipioChartOptions(
            this.dadosKpisByMunicipio.pat2,
            this.listaMunicipios,
            "pat2"
          );
          this.pat3ChartOptions = this.getKpisByMunicipioChartOptions(
            this.dadosKpisByMunicipio.pat3,
            this.listaMunicipios,
            "pat3"
          );
          this.pat4ChartOptions = this.getKpisByMunicipioChartOptions(
            this.dadosKpisByMunicipio.pat4,
            this.listaMunicipios,
            "pat4"
          );
        })
        .catch(err => {
          throw err;
        });
    },
    getSortedCategories() {
      const sortedValues = this.tipoOrdenacao === 'asc' 
        ? this.dadosKpisByMunicipio[this.indicadorOrdenacao].sort((a, b) => Number(a.valor ?? 0) - Number(b.valor ?? 0))
        : this.dadosKpisByMunicipio[this.indicadorOrdenacao].sort((a, b) => Number(b.valor ?? 0) - Number(a.valor ?? 0));

      return sortedValues.map(el => el.nome_municipio);
    },
    getDadosKpisByMunicipioCompetenciaAnterior() {
      return DashboardLnoService.getDadosKpisByMunicipio(
        dayjs(this.competencia).subtract(1, 'month').format('YYYY-MM'),
        this.tipoCalculo,
        this.regionalSelecionadaId
      )
        .then(res => {
          this.dadosKpisByMunicipioCompetenciaAnterior = res;
        })
        .catch(err => {
          throw err;
        });
    },
    getRegionais(regional){
      return MunicipiosService.getRegionais(regional)
        .then(res => {
          this.regionais = res;
          if(this.regionalSelecionada == null){
            this.regionalSelecionada = res.find((el) => el.nome.toLowerCase() == 'corporativa').nome
          }
        })
        .catch(err => {
          throw err;
        });
    },
    getMetasKpisByMunicipio() {
      return DashboardLnoService.getMetasKpisMunicipios(
        this.competencia,
        this.regionalSelecionadaId
      )
        .then(response => {
          this.metasKpisMunicipio = response;
        })
        .catch(err => {
          throw err;
        });
    },
    getDadosTotais() {
      return DashboardLnoService.getDadosTotaisIndicadoresLnoMunicipio(
        this.competencia, 
        this.competencia,
        this.tipoCalculo,
        this.regionalSelecionadaId
      )
        .then(res => {
          this.dadosTotais = res;
        })
        .catch(err => {
          throw err;
        });
    },
    getDadosTotaisAnteriores() {
      return DashboardLnoService.getDadosTotaisIndicadoresLnoMunicipio(
        dayjs(this.competencia).subtract(1, 'month').format('YYYY-MM'),
        dayjs(this.competencia).subtract(1, 'month').format('YYYY-MM'),
        this.tipoCalculo,
        this.regionalSelecionadaId
      )
        .then(res => {
          this.dadosTotaisAnteriores = res;
        })
        .catch(err => {
          throw err;
        });
    },
    getKpisByMunicipioChartOptions(dados, categories, indicador) {
      return {
        chart: {
          type: 'column',
          height: 400,
          zoomType: 'x',
        },
        credits: { enabled: false },
        title: { text: null },
        subtitle: { text: null },
        legend: { enabled: false },
        xAxis: {
          categories: categories,
          crosshair: true,
          title: { 
            text: 'Município'
          }
        },
        yAxis: {
          title: { text: null },
          max: indicador === 'pla' ? 100 : null,
        },
        lang: {
          noData: "Sem dados para exibição"
        },
        series: {
            name: "Valor",
            animation: false,
            data: categories
              .map(category => {
                const index = dados.findIndex(el => el.nome_municipio === category);
                return index !== -1
                  ? { 
                    x: categories.indexOf(category), 
                    y: Number(dados[index].valor),
                    color: Number(dados[index].valor) > Number(this.metasKpisMunicipio?.[dados[index].codigo_ibge]?.[indicador]) ? "#FF5252" : "#2196F3"
                  } 
                  : { x: categories.indexOf(category), y: null }
              })
              // .filter(val => val !== null && val.y !== null && val.y > 0)
          },
          exporting: this.exportingOptions,

      }; 
    },
  },
  watch: {
    activatedTab() {
      this.getDados();
    },
    competencia() {
      this.getDados();
    },
    tipoCalculo() {
      this.getDados();
    },
    indicadorOrdenacao() {
      this.getDados();
    },
    tipoOrdenacao() {
      this.getDados();
    },
    regionalSelecionada(){
      this.getDados();
    }
  }
}

</script>

<style>
.parametros-v-card {
  box-shadow: 0px 0px 0px !important;
}
</style>